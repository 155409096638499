.video-container h4{
    color: #102a63;
}
.video-container .btn{
    background-color: #102a63;
    color:#FFFFFF;
    text-transform: uppercase;
    width: 300px;
    margin-top: 2%;
    font-size: .9em;
    padding: 1.5% 0;
}
.video-container .btn:hover{
    color:#FFFFFF;
}