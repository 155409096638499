@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700&display=swap');
.cardx {
  max-width: 610px;
  margin: auto;
  background-color: #ffffff;
  padding: 3% 3% 2%;
  border-radius: 30px 0;
}
.info-container h3,
.info-container p {
  line-height: 1.1em;
  margin-bottom: 0.5rem;
}
.info-p span{
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
.info-bold{
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
}
.nova {
  position: absolute;
  bottom: 0;
  background-color: #102a63;
  color: #ffffff;
  padding: 1%;
  font-size: 0.7em;
  border-radius: 0 25px 0 0;
  margin-left: -3%;
}
.borderlist {
  list-style: none;
  border-left: 2px solid #000000;
  padding: 0 2%;
  color: #102a63;
  
}
.borderlist li{
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
  line-height: 1.4em;
}
.cardx p {
  font-size: 0.8em;
  color: #102a63;
}
.cardx h3,
.font-bold {
  color: #102a63;
}
.dura {
  width: 70px;
  position: absolute;
  right: 5%;
  top: 0.5%;
  opacity: .7;
}
.lata-container{
  position:relative;
  overflow: hidden;
}
.baixe{
  width: fit-content;
  float: left;
  margin-right: 5%;
}
.cores-container{
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 5px;
  flex-wrap: wrap;
}
.cores-container p{
  margin-right: 5px;
  margin-bottom: 0px;
}
.cor-items{
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
}
.cor-item{
  max-width:60px;
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.cor-item img{
  border-radius: 10px 0;
  position: absolute;
}
.cor-item .cor-name {
  font-size: 8px;
  color: white;
  width: 100%;
  text-align: center;
  line-height: 9px;
  font-weight: 100;
  z-index: 1;
  text-shadow: 1px 1px 10px black;
}
.cor-name-no-img {
  line-height: 1.1em;
  margin-bottom: .5rem;
  font-size: .8em;
  color: #102a63;
  font-weight: 300;
  font-family: "Open Sans",sans-serif;
}
@media (max-width: 767px) {
  .cardx h3{
    font-size: 1.5rem;
  }
  .cardx {
    max-width: 100%;
    border-radius: 0;
    font-size: 0.6em;
  }
  .cardx p {
    font-size: 1em;
  }
  .lata-container {
    padding-top: 10%;
    text-align: center;
  }
  .nova {
    top: 0;
    bottom: auto;
    border-radius: 0 0 15px;
    padding: 2% 10% 2% 6%;
  }
  .dura {
    width: 30px;
    right: 1%;
    top: 0.5%;
  }
  /* .info-container{
    margin-top: 100%;
  } */
}
