#main-banner{
    /* margin-top: 9%; */
    background-size: cover;
    padding: 5% 0 2.5%;
}
#main-banner-original{
    /* margin-top: 9%; */
    background-image: url("../../img/house2.jpg");
    background-size: cover;
    padding: 5% 0 2.5%;
}
.banner-mobile{
    display:none !important;
}
@media (max-width: 1339px ) {
    #main-banner .container {
        min-height: 29vw !important;
    }
}
@media (min-width: 1440px ) {
    #main-banner .container {
        min-height: 35vw !important;
    }
}

#main-banner .container .row {
    display: none !important;
}
h1{
    text-align: center;
    font-family: colourSansBold;
    color: #ffffff;
    font-size: 3.3rem !important;
}
#lata{
    margin: 15% 0;
}
@media (min-width:992px){
    #main-banner{
        margin-top: 97px;
        padding-top: 97px;
    }
}
@media (min-width:768px) and (max-width:991px){
    #main-banner{
        margin-top: 99px;
        padding-top: 99px;
    }
} 
@media (max-width:767px){
    #main-banner{
        margin-top: 0;
        background-image: url("../../img/Cetolteca_Banner_Landing_755x606_clobol.jpg");
        padding:0;
    }
    #main-banner-original{
        margin-top: 0;
        background-image: url("../../img/house-mobile.jpg");
        padding:0;
    }
    .banner-mobile{
        display:flex !important;
    }
    .banner-desktop{
        display:none !important;
    }
    #main-banner .container {
        min-height: 80vw !important;
    }    
    #lata{
        max-width: 50%;
    }
    h1{
        font-size: 2rem !important;
        margin-bottom: 10% !important;
    }
}

#main-banner-original {
    padding-top: 80px;
  }
  
  @media (max-width: 991px) {
    #main-banner-original {
      padding-top: 69px;
    }
  }
  