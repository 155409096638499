.bg {
  background-image: url(../../img/header3.jpg);
  background-size: cover;
  background-repeat: repeat;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  /* border-top: #112A62 20px solid; */
}
header .nav-link {
  color: #fff !important;
  cursor: pointer;
}
header {
  height: 5em;
  background-size: contain;
}

header.shrink {
  height: 5em;
  /* font-size: .9em; */
  background-size: contain;
}
.logo.shrink {
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  margin-top: -5%;
}
.nav.shrink {
  padding-top: 0;
}
/* .navbar-nav.shrink{
  margin-top: -20px;
} */
/* .navbar-brand{
  padding: 0;
  margin-top: -10px;
} */
.navbar-brand.shrink {
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
}
/* .navbar {
  padding: 0!important;
} */
/* #logo-desktop{
  width: 110%;
}
.navbar-nav {
  margin-top: 0;
} */

/*@media (max-width: 767px) {*/
@media (max-width: 991px) {
  .bg {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background-image: url(../../img/header-mobile.jpg);
    background-size: 100% 70px;
    background-repeat: no-repeat;
  }
  .logo {
    width: 130px;
    margin-left: 20px;
  }
  .navbar-toggler {
    /* background-color: rgba(255,255,255,0.4) !important;
        border-color: #FFF  !important; */
  }
  #hamburguer {
    width: 40px;
  }
  header .container,
  header .navbar {
    padding: 0;
  }
  header.shrink {
    background-repeat: round !important;
  }
  .navbar-collapse {
    background-color: rgba(0, 38, 101, 0.8);
    text-align: center;
    padding: 7.5% 0;

    /*position: absolute;*/
    width: 104%;
    /*top: 65px;*/
  }
  .nav-link {
    font-size: 1.6em;
    border-bottom: 2px solid rgba(255, 255, 255, 0.7);
    width: 70%;
    margin: auto;
  }
  .nb{
      border-bottom: none;
  }
  .liredes{
    margin-top: 20px !important;
    width: 40%;
    margin: auto;
  }
  .owl-theme .owl-nav{
    position: absolute;
    width: 50%;
    left: 25%;
    bottom: 50%;
  }

  .owl-carousel .owl-nav .owl-prev{
    font-size: 30px !important; 
    float: left;
  }
  .owl-carousel .owl-nav .owl-next{
    font-size: 30px !important; 
    float: right;
  }
    
  
}

