.house-bg{
    background-image: url(../../img/house.jpg);
    background-size: cover;
    padding-top: 7.5%;
}
.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next  {
    background: #102A63;
}
  
  
/* @media (min-width: 992px) { */
    .house-bg {
      overflow: hidden;
    }
    .owl-carousel {
      width: 150%;
      margin-left: -25%;
    }
    .owl-carousel .owl-stage-outer {
      overflow: initial;
    }
    .owl-stage-outer,
    .owl-stage {
        padding-bottom: .5% !important;
    }
    .owl-nav{
        padding-bottom: 2%;
    }
    /* .firstActiveItem, .lastActiveItem{
        opacity: .7;
    }
    .secondActiveItem{
        opacity: 1;
    }   */
/* } */
@media(max-width:767px){
    .owl-carousel {
        width: 250%;
        margin-left: -75%;
      }
    .house-bg{
        background-image: url(../../img/house-mobile.jpg);
        padding: 30% 10% 20% 10%;
    }
    /* .secondActiveItem{
        transform: scale(1.7, 1.7);
        opacity: 1;
    }  */
}