@font-face {
  font-family: colourSans;
  src: url(./fonts/ColourSans.otf);
}
@font-face {
  font-family: colourSansLight;
  src: url(./fonts/ColourSans-Light.otf);
}
@font-face {
  font-family: colourSansBold;
  src: url(./fonts/ColourSans-Bold.otf);
}
*{
  font-family: colourSans, sans-serif;
}
.font-bold{
  font-family: colourSansBold;
  color:#333333;
}
.font-light{
  font-family: colourSansLight;
}
.container-fluid{
  /* max-width: 1566px; */
  margin:auto;
  padding:0 !important;
  overflow-x: hidden !important;
}
.navbar-nav{
  margin-top: -5%;
}
.nav-link{
  color:#fff;
  text-transform: uppercase;
    font-weight: 500;
}
.modal-backdrop.show{
  opacity: 0;
  /* background-color: rgba(0,0,0, 1) !important;
  opacity: .1; */
  z-index: 1;
}
.modal-open .modal{
  background-color: rgba(0,0,0,.7);
}
.modal-content {
  background-color: transparent !important;
  border:0;
}
.modal-body{
  color: #FFF;
    font-size: 2rem;
}
.modal-body h3{
  font-size: 4rem;
    line-height: .8;
    font-weight: bold;
}
.modal-body p{
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-weight: 800;
}
.modal-body a{
  font-size: 2rem;
    line-height: 1.5rem;
    text-decoration: underline !important;
}

@media (min-width: 576px){
  .modal-dialog {
      max-width: 500px;
      /* margin: 6rem auto !Important; */
  }
}