.slide img {
  width: 100%;
}

.rowCarousel {
  padding-top: 80px;
}

@media (max-width: 991px) {
  .rowCarousel {
    padding-top: 69px;
  }
}
