.containerx{
    background-image: url(../../img/wood.jpg);
    background-size: cover;
    padding:5%;
}
h4{
    width: fit-content;
    margin: auto;
}
#uma{
    line-height: 18px;
}
.boxlist{
    padding-left: 15%;
}
.boxlist li{
    list-style: none;
    border: 2px solid;
    float: left;
    padding: 0 1%;
    font-family: ColourSansBold;
    margin-right: 1%;
}
#h4tecno{
    color:#333333;
}

@media (max-width: 767px) {
    .boxlist{
        padding-left: 0;
    }
    #tecnologia{
        max-width: 60%;
        margin: 20% auto 5%;
    }
    #nossos{
        font-size:.9em;
    }
    h4{
        font-size: 1.4em !important;
    }
    #evolucao{
        font-size: 1.1em;
        margin-bottom: 5%;
    }
    #uma{
        line-height: inherit;
    }
    .boxlist{
        padding:0;
    }
    .boxlist li{
        float: none;
        width: fit-content;
        margin: 3% auto;
        font-size: 1.4em;
        padding: 0 2%;
        border-width: 1.5px;
    }
}