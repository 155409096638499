.wood-bg {
  background-image: url(../../img/madera.jpg);
  background-size: cover;
}
.fa {
  font-size: 1.7em !important;
  color: #ffffff;
}
.icon-container {
  text-align: center;
  padding: 15% 10%;
  border-radius: 1000%;
  border: 3px solid #fff;
}
footer a{
    color: #FFF !important;
    text-transform: uppercase;
    font-family: ColourSansBold;
    font-size: .8em;
}
#copy{
  color: #FFF;
    font-size: .7em;
}
footer img{
  cursor: pointer;
}
.optanon-toggle-display {
  cursor: pointer;
}
